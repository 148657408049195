import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import BlogIndexSection from "../components/blog-index-section";
import Hero from "../components/hero";

const Category = (props) => {
  const {
    data: {
      archive: {
        uri,
        name,
        description,
        posts: { nodes: posts },
        caseStudies: { nodes: caseStudies },
      },
    },
  } = props;

  return (
    <Layout
      seo={{ ...props.data.archive?.seo, canonical: uri, opengraphUrl: uri }}
    >
      <div className="md:w-2/3 mx-auto">
        <Hero title={name}></Hero>
        <BlogIndexSection posts={[...posts, ...caseStudies]}></BlogIndexSection>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query category($id: String!) {
    archive: wpCategory(id: { eq: $id }) {
      id
      name
      description
      uri
      slug
      posts {
        nodes {
          id
          uri
          title
          excerpt
          date
          categories {
            nodes {
              id
              name
            }
          }
          author {
            node {
              avatar {
                url
              }
              name
            }
          }
        }
      }
      caseStudies {
        nodes {
          id
          uri
          title
          excerpt
          date
          categories {
            nodes {
              id
              name
            }
          }
          author {
            node {
              avatar {
                url
              }
              name
            }
          }
        }
      }
      ...WpSEOCategory
    }
  }
`;

export default Category;
